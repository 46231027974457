import RulesCard from './RulesCard';
function Rules() {
  
  const rules = [
    "1. 	வாடிக்கையாளரின் குறிப்பிட்ட ஆவணப்பதிவு இல்லாமல் பணம் தர இயலாது. (ஆதார் கார்டு, பான் கார்டு, ஸ்மார்ட் கார்டு, வங்கிப் புத்தகம்) இதில் ஏதேனும் இரண்டு கண்டிப்பாக கொடுக்க வேண்டும்.",
    "2. 	வாடிக்கையாளர்கள் வாரத் தவணையை அந்தந்த தேதிக்குள் கட்டாயம் செலுத்த வேண்டும்.",
    "3.   தவனை காலம் முடியும் தேதியில் தவணையை கட்டி முடிக்க வேண்டும். அப்படி தவறும் பட்சம் குறிப்பிட்ட சதவீதத்தில் அபராதம் வசூலிக்கப்படும்.",
    "4.   வசூலிப்பவர்களின் கையொப்பம் இல்லாமல் வாடிக்கையாளர்கள் தவணை தொகையை செலுத்தக் கூடாது.",
    "5.  	பணம் செலுத்திய பின்பு அவரவர் பாஸ்புக்கில் வரவு வைத்துக் கொள்ள வேண்டும்.",
    "6. 	கையெழுத்து இல்லாமல் தவணை தொகையை செலுத்தினால் அதற்கு நிர்வாகம் பொறுப்பு ஏற்காது.",
    "7.   புது வாடிக்கையாளர் என்றால் குழு ஒப்புதல் கையெழுத்துடன் தான் தவணை வழங்கப்படும்.",
    "8. 	10000க்கு மேல் தவணை வாங்குபவர்கள் காசோலை (Cheque Sleep) இல்லாமல் வழங்கப்படமாட்டது.",
    "9. 	தவணை கட்ட தவறும் பட்சத்தில் சட்டபடி நடவடிக்கை அல்லது அதன் பெருமான பொருள்களை எடுத்து செல்லும் உரிமை எங்களுக்கு உண்டு.",
    "10. •	மேற்கண்ட நிபந்தனைகளுக்கு உட்பட்டு தவணை தொகையை பெற்றுக்கொள்கிறேன்.",
                                                
  ];


  return (
    <section id="rules">
    <div className='rules-container'>
      
      <div className='rules-list'>
      <h1>விதிமுறைகள்</h1>
      <span><hr></hr></span>
        {rules.map((rule, index) => (
          <RulesCard key={index} rule={rule} />
        ))}
      </div>
      <br></br>
      {/* <h5>இப்படிக்கு,</h5>
      <br></br>
      <h5>உறுப்பினர் கையொப்பம்.</h5>
      <br></br> */}
    </div>
    </section>
  );
}

export default Rules;