import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import logoImage from './finance_logo1.png';

function Navbar() {
    const [navActive, setNavActive] = useState(false);
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(true);
        window.open('https://app.kafinance.in', '_blank', 'noopener,noreferrer');
    };

    const toggleNav = () => {
        setNavActive(!navActive);
    };

    const closeMenu = () => {
        setNavActive(false);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 500) {
                closeMenu();
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (window.innerWidth <= 1200) {
            closeMenu();
        }
    }, []);

    return (
        <nav className={`navbar ${navActive ? "active" : ""}`}>
            <div className="logo">
                <img src={logoImage} alt="Logoimage" />
            </div>
            <a className={`nav_hamburger ${navActive ? "active" : ""}`} onClick={toggleNav}>
                <span className="nav_hamburger_line"></span>
                <span className="nav_hamburger_line"></span>
                <span className="nav_hamburger_line"></span>
            </a>
            <div className={`navbar--items ${navActive ? "active" : ""}`}>
                <ul>
                    <li>
                        <Link onClick={closeMenu} activeClass="navbar--active-content" spy={true}
                            smooth={true} offset={-70} duration={500} to="heroSection"
                            className="navbar--content">Home</Link>
                    </li>
                    <li>
                        <Link onClick={closeMenu} activeClass="navbar--active-content" spy={true}
                            smooth={true} offset={-70} duration={500} to="AboutMe"
                            className="navbar--content">About Me</Link>
                    </li>
                    <li>
                        <Link onClick={closeMenu} activeClass="navbar--active-content" spy={true}
                            smooth={true} offset={-70} duration={500} to="RulesCard"
                            className="navbar--content">Rules</Link>
                    </li>
                    <li>
                        <Link onClick={closeMenu} activeClass="navbar--active-content" spy={true}
                            smooth={true} offset={-70} duration={500} to="Footer"
                            className="navbar--content">Contact us</Link>
                    </li>
                    <li>
                        <button onClick={handleClick} activeClass="navbar--active-content" className="navbar--content">
                            {clicked ? 'Signup' : 'Login'}
                        </button>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;
