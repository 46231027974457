import HeroSection from "../HeroSection";
import AboutMe from "../AboutMe";
// import Testimonial from "../Testimonials";
import ContactMe from "../ContactMe";
import Rules from "../Rules";
import Footer from "../Footer";


export default function Home () {
    return(
        <div>
            <HeroSection></HeroSection>
            <AboutMe></AboutMe>
            <Rules></Rules>
            {/* <Testimonial></Testimonial> */}
            {/* <ContactMe></ContactMe> */}
            <Footer></Footer>
        </div>
    )
}