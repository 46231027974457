import heroImage from './personal.png';

export default function AboutMe(){
    return(
        <section id="AboutMe">
            <div className="about-section">
            <div className="about-section-img">
                <img src={heroImage} alt="About Me" />
            </div>
            <div className='hero-section-content-box  about-section-box'>
                <div className='hero-section-content'>
                    {/* <p className='section-title'>About</p> */}
                    <h1 className='skills-section-heading'>நிறுவனத்தைப் பற்றி</h1>
                    <p className='hero-section-description'>
                    கே ஏ நிதி நிறுவனம், நிதி சேவைகளில் முதன்மை ஆதாரமாக உள்ள நிறுவனம் ஆகும். நாங்கள் எங்கள் வாடிக்கையாளர்களுக்கு நிதி மேலாண்மை, முதலீடு, கடன் ஆலோசனை, மற்றும் பாதுகாப்பு சேவைகளை வழங்குவதில் பெருமைக் கொள்கிறோம். நாங்கள் நவீன நிதி தீர்வுகளை எளிமையாகவும் அணுகுமுறையாகவும் வழங்க, உங்களுக்கு எப்போதும் உதவ தயாராக உள்ளோம்.
                    </p>
                    <h4>எங்கள் சேவைகள்:</h4>
                    <p className='hero-section-description'>
                    1. நிதி ஆலோசனை<br></br>2. முதலீட்டுத் திட்டங்கள்<br></br>
                    3. கடன் சேவைகள்<br></br>4. பாதுகாப்பு சேவைகள்
                    </p>
                </div>
            </div>
            </div>
        </section>
    )
}