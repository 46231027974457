import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import logoImage from './finance_logo1.png';

function Footer() {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
      setClicked(true);
      window.open('https://app.kafinance.in', '_blank', 'noopener,noreferrer');
    };
    return (
        <footer id="Footer" className="footer-container">
            <div className="footer--link--container">
                <img src={logoImage} alt="Logoimage" />
                
                <h5>9.F, J.K. காம்ப்ளைக்ஸ், டெலிபோன் ரோடு, அருப்புக்கோட்டை.</h5>
                <h5>மேலும் விவரங்களுக்கு : +91 83004 97783</h5>
            </div>
            
            <div className="footer--items">
                <ul>
                    <li>
                        <Link activeClass="navbar--active-content" spy={true}
                            smooth={true} offset={-70} duration={500} to="heroSection"
                            className="navbar--content">Home</Link>
                    </li>
                    <li>
                        <Link activeClass="navbar--active-content" spy={true}
                            smooth={true} offset={-70} duration={500} to="AboutMe"
                            className="navbar--content">About Me</Link>
                    </li>
                    <li>
                        <Link activeClass="navbar--active-content" spy={true}
                            smooth={true} offset={-70} duration={500} to="RulesCard"
                            className="navbar--content">Rules</Link>
                    </li>
                    <li>
                        <Link activeClass="navbar--active-content" spy={true}
                            smooth={true} offset={-70} duration={500} to="Footer"
                            className="navbar--content">Contact Us</Link>
                    </li>
                    <li>
                        <button onClick={handleClick}>
                            {clicked ? 'Signup' : 'Login'}
                        </button>
                    </li>
                </ul>
                {/* <Link activeClass="navbar--active-content" spy={true}
                    smooth={true} offset={-70} duration={500} to="Contact"
                    className="btn btn-outline-primary">Login</Link> */}

            </div>
            <div className="footer--social--icon">
                <div className="social-icons">
                    <a href="https://www.facebook.com"><i className="fab fa-facebook"></i></a>
                    <a href="https://twitter.com"><i className="fab fa-twitter"></i></a>
                    <a href="https://www.instagram.com"><i className="fab fa-instagram"></i></a>

                </div>
            </div>
            <hr className="divider"></hr>
            <div className="footer--content--container">
                <a className="footer--content" href="https://digianalytix.com/" >DIGIANALYTIX</a>
                <div className="footer--social--icon">
                    <ul>
                        <li>
                            <Link activeClass="navbar--active-content" spy={true}
                                smooth={true} offset={-70} duration={500} to="Privacy_Policy"
                                className="navbar--content">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link activeClass="navbar--active-content" spy={true}
                                smooth={true} offset={-70} duration={500} to="Terms_of_Service"
                                className="navbar--content">Terms of Service</Link>
                        </li>
                        <li>
                            <Link activeClass="navbar--active-content" spy={true}
                                smooth={true} offset={-70} duration={500} to="Cookies_Settings"
                                className="navbar--content">Cookies Settings</Link>
                        </li>
                    </ul>

                </div>
                <br></br>
                <div>copyright © Digianalytix@2024</div>
            </div>

        </footer>
    )
}


export default Footer