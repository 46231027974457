import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from './Pages/Home/Navbar';
import Home from './Pages/Home/Homescreen';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css'; // Ensure you have the correct path
// import FilterExample from './Pages/Home/FilterExample';


function App() {
  return (
    <div className="App">
      <Router>
        <div>
          {/* <FilterExample/> */}
          
          <Navbar />
          
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="*" element={<div>404 Not Fount</div>}></Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
