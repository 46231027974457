import React from 'react';


function RulesCard({ rule }) {
  return (
    <section id="RulesCard">  
      <div className="card">
      <div className="card-content">
        <p>{rule}</p>
      </div>
    </div>
   </section>
  );
}

export default RulesCard;