import heroImage from './hero-2.png';
import heroImage_1 from './capture-1.png';
import iconImg_1 from './icon-1.png';
import iconImg_2 from './icon-2.png';
import iconImg_3 from './icon-3.png';
import logo from './finance_logo1.png';


export default function HeroSection() {
    return (
        <section  id="heroSection">
            <div className=" row hero-section">
                <div className="col-sm-6 hero-section-content-box">
                    <div className="hero-section-content">
                   
                        <h1 className="hero-section-title">
                            <span className="hero-section-title-color">KA FINANCE</span>{" "}
                            <br></br>
                            நிதி நிறுவனம்
                        </h1>
                    
                        <p className="hero-section-description">
                        வணக்கம்! கே ஏ நிதி நிறுவனத்திற்கு உங்களை வரவேற்கின்றேன். நாங்கள் உங்கள் நிதி திட்டத்தை மேம்படுத்த, வளர்ச்சிக்கு உத்தியாகவும் நீங்கள் உங்கள் திறன்களை அதிகரிக்க உதவ,
                        உங்கள் கனவை நிறுவுவதில் உங்களுக்கு உதவுவோம்.
                        <br></br>
                        கே ஏ நிதி நிறுவனத்தில் எங்களுக்கு முக்கியமான சிறந்த நிதி திட்டங்கள் உள்ளன. 
                        </p>
                    </div>
                   <button href="https://app.kafinance.in" className="btn btn-primary"> பதிவு செய்ய</button>
                </div>
            
                <div className="col-sm-6 hero-section-img">
                    <img src={heroImage} alt="Hero Section" />
                </div>        
            </div>
                <div className='row center-part'>
                    <h2>ஏன் கே ஏ நிதி?</h2>
                    <span><hr></hr></span>
            <div className='col-sm-4 left'>
                <h5>01</h5>
                <h3>நம்பகத்தன்மை</h3>
                <img src={iconImg_1} alt='icon-image' />
                <hr />
                <p>எங்கள் வாடிக்கையாளர்களின் நிதி பாதுகாப்பு மற்றும் வளர்ச்சியில் முழுமையான நம்பகத்தன்மையுடன் செயல்படுகிறோம்.</p>   
            </div>
            <div className='col-sm-4 middle'>
                <h5>02</h5>
                <h3>தனிப்பட்ட சேவை</h3>
                <img src={iconImg_2} alt='icon-image' />
                <hr />
                <p>எங்கள் வாடிக்கையாளர்களின் தனிப்பட்ட நிதி தேவைகளுக்கு ஏற்ப சேவைகளை மையமாக்கி வழங்குகிறோம்.</p>   
            </div>
            <div className='col-sm-4 right'>
                <h5>03</h5>
                <h3>நவீனத் தொழில்நுட்பம்</h3>
                <img src={iconImg_3} alt='icon-image' />
                <hr />
                <p>நாங்கள் நவீனத் தொழில்நுட்பத்தை பயன்படுத்தி நிதி சேவைகளை எளிமையாக்கி, தரமான சேவைகளை வழங்குகிறோம்.</p>   
            </div> 
        </div>

            
        </section>
    )
}